<template>
  <div>
    <b-modal
      centered
      size="lg"
      ref="my-modal"
      title="Images"
      hide-footer
      no-close-on-backdrop
    >
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="4000"
        controls
        indicators
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="sliding = true"
        @sliding-end="sliding = false"
      >
        <span v-if="images.length">
          <b-carousel-slide
            v-for="(image, index) in images"
            :key="index"
            :img-src="image"
          ></b-carousel-slide>
        </span>
        <div style="text-align: center;" v-else>
          <span style="font-size: 50px">
            NO IMAGE UPLOADED
          </span>
        </div>
      </b-carousel>
      <br>
      <span class="hint">
        Please click on Edit part button 
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.57012 3.88944L10.1106 6.42989M9.20334 1.6514L2.33082 8.52392C1.97572 8.87853 1.73354 9.33032 1.63481 9.82235L1 13L4.17766 12.364C4.66966 12.2656 5.12087 12.0244 5.47608 11.6692L12.3486 4.79666C12.5551 4.59014 12.7189 4.34496 12.8307 4.07513C12.9425 3.8053 13 3.51609 13 3.22403C13 2.93197 12.9425 2.64276 12.8307 2.37293C12.7189 2.1031 12.5551 1.85792 12.3486 1.6514C12.1421 1.44488 11.8969 1.28106 11.6271 1.16929C11.3572 1.05753 11.068 1 10.776 1C10.4839 1 10.1947 1.05753 9.92487 1.16929C9.65504 1.28106 9.40986 1.44488 9.20334 1.6514Z" stroke="#004085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
</svg>
        to Add or Update your part's image.
      </span>
      <br>
      <b-button class="btn mt-4 btn custom-btn custom-danger" @click="$emit('close')">{{
        $t("commonLayout.close")
      }}</b-button>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    images: {
      type: Array
    }
  },
  data() {
    return {
      slide: 0,
      sliding: null
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.slide = 0;
        this.showModal();
      } else {
        this.hideModal();
      }
    }
  },
  methods: {
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    }
  }
};
</script>
